.attechment-box .attechment-file-label {
    width: 100px;
    height: 100px;
    background: #efefef;
    border-radius: 3px;
}

label.attechment-label {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #212529;
    word-break: break-word;
    cursor: default;
    -webkit-tap-highlight-color: transparent;
}

img.attachment {
    height: 100%;
    width: 100%;
}

.attechment-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
}