@font-face {
  font-family: Poppins;
  src: url(../../assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: montserrate;
  src: url(../../assets/fonts/Montserrat-VariableFont_wght.ttf);
}

.auth-form {
  width: 100%;
}

.auth-form label {
  margin-bottom: 6px;
  font-size: 14px;
  font-family: var(--poppins);
}

.toplogodiv {
  width: 150px;
  margin: 0 auto;
}

.signintoplogoimg {
  padding-top: 10px;
  width: 150px;
  height: 150px;
}

.signupheading {
  font-family: "montserrate";
  font-size: 40px;
  margin-top: 10px;
  color: #dd995f;
}

.radiodiv {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}

.signinbtn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  background-color: #dd995f;
  width: 100%;
  border: none;
  outline: none;
}

button .spinner-border {
  width: 20px;
  height: 20px;
}

.eye-icon {
  margin-top: 12px;
  margin-right: 5px;
}

.required-input label:after {
  content: '*';
  position: absolute;
  right: -10px;
  color: red;
  font-size: 16px;
  top: -5px;
}

.required-input label {
  position: relative;
}

.signinbtn {
  background: linear-gradient(180deg, #36d33f 0%, #097f1d 100%);
  transition: 0.4s ease-in-out;
  border: none !important;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
}
.csv-data.location-csv {
  display: grid;
  grid-template-columns: repeat(5,1fr);
  margin-top: 40px;
  background: #f9f9f9;
  padding: 20px 10px;
  border-radius: 9px;
  position: relative;
  overflow-x: auto;
  grid-gap: 10px;
}
span.csv-data {
  border-bottom: 1px solid #f5e3e3;
}
.signinbtn:hover {
  background-color: #c08149 !important;
}

.radiolabel input {
  font-size: 15px;
}

.radioinput {
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
}

.radioinput:checked:after {
  content: " ";
  border: 4px solid #dd995f;
  background-color: #ffffff;
  width: 13px;
  height: 13px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.radioinput {
  position: relative;
  margin-left: 10px;
  accent-color: #dd995f;
  border: 3px solid #fff;
  outline: white;
}

.signinsubmitbtndiv {
  width: 70%;
  margin: 0 auto;
}

.checkboxinput {
  font-size: 14px;
}

.forgottext {
  font-size: 14px;
  color: #dca54a;
  text-decoration: none;
}

.forgottext:hover {
  color: #dca54a;
  text-decoration: underline;
}

.createnewaccount {
  margin: 0 auto;
  text-align: center;
}

.logosdiv {
  width: 30%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}