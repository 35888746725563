.detailGroupItems {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.detailGroupItems label {
    font-size: 1rem;
    font-family: 'Raleway';
    font-weight: 800;
    color: rgb(56, 48, 48);
}

.detailGroupItems span {
    font-size: 1rem;
    font-family: 'Raleway';
    font-weight: 600;
}