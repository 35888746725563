.profile-pic-lawyer{
    width: 200px;
    height: 200px;
}

.case-date{
    font-size: 18px;
}
@media screen and (max-width: 576px) {
.case-date{
    font-size: 10px;
}
}